import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { allowAdvertisingCookies } from './allowAdvertisingCookies';
import { allowPerformanceCookies } from './allowPerformanceCookies';
import { allowAllCookies } from './allowAllCookies';
import { denyPerformanceAdvertisingCookies } from './denyPerformanceAdvertisingCookies';

import type { CookieCategories } from 'components/CookiePreferences/types';

export const useGoogleTagManager = () => {
  const [gtmInitialized, setGtmInitialized] = useState(false);
  const [sendGTMPreferences, setSendGTMPreferences] = useState(false);
  const [advertisingPreferences, setAdvertisingPreferences] = useState(false);
  const [performancePreferences, setPerformancePreferences] = useState(false);

  const initializeGoogleTagManager = (
    { advertising, performance }: CookieCategories,
    isBannerOpen: boolean
  ) => {
    const tagManagerArgs = {
      gtmId: process.env.GTM_CONTAINER_ID || 'GTM-5HSWD9', // default is our production container
      includeInDevelopment: false,
      defaultDataLayer: { platform: 'gatsby' },
      enableWebVitalsTracking: true,

      routeChangeEventName: 'gatsby-route-change',
    };

    // initialises gtm if it hasn't already been initialised
    if (!gtmInitialized && !isBannerOpen) {
      TagManager.initialize(tagManagerArgs);
      setGtmInitialized(true);
      setSendGTMPreferences(true);
      setAdvertisingPreferences(advertising);
      setPerformancePreferences(performance);
    }
  };

  useEffect(() => {
    // if both advertising and performance cookies are denied, then all gtm tags will be denied
    if (sendGTMPreferences && !advertisingPreferences && !performancePreferences) {
      denyPerformanceAdvertisingCookies();
      setSendGTMPreferences(false);
    }

    // if advertising cookies are accepted, then gtm advertising tags will be allowed to fire
    if (sendGTMPreferences && advertisingPreferences && !performancePreferences) {
      allowAdvertisingCookies();
      setSendGTMPreferences(false);
    }

    // if performance cookies are accepted, then gtm performance tags will be allowed to fire
    if (sendGTMPreferences && performancePreferences && !advertisingPreferences) {
      allowPerformanceCookies();
      setSendGTMPreferences(false);
    }

    /*
     * if performance and advertising cookies are accepted, then gtm all tags will be allowed to fire
     * (only performance and advertising tags are currently in gtm)
     */
    if (sendGTMPreferences && performancePreferences && advertisingPreferences) {
      allowAllCookies();
      setSendGTMPreferences(false);
    }
  }, [advertisingPreferences, performancePreferences, sendGTMPreferences]);

  return { initializeGoogleTagManager };
};

import TagManager from 'react-gtm-module';

export const allowAllCookies = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'gdpr_all_cookies_allowed',
    },
  };

  TagManager.dataLayer(tagManagerArgs);

  const tagManagerPageviewArgs = {
    dataLayer: {
      event: 'manual_pageview_trigger',
    },
  };

  TagManager.dataLayer(tagManagerPageviewArgs);
};

/*
 * Here lives all of our tokens for our design system. Ideally none of these values should ever change.
 * These tokens are used in our styled components to define the look and feel of our design system.
 */

export interface Tokens {
  typography: {
    fontSizes: {
      [key: string]: `${string}rem`;
    };
    lineHeights: {
      [key: string]: `${string}rem`;
    };
    fontFamily: {
      [key: string]: 'Signifier' | 'Nunito Sans' | 'Helvetica' | 'sans-serif';
    };
    fontWeights: {
      [key: string]: number;
    };
  };
  baseSize: number;
  spacing: {
    [key: string]: `${string}px`;
  };
  colors: {
    [key: string]: `#${string}`;
  };
}

export const tokens: Tokens = {
  typography: {
    fontSizes: {
      /** decorative super large text desktop */
      xxxl: '10rem',
      /** h1 desktop, decorative super large text mobile  */
      xxl: '5rem',
      /** h2 desktop, h1 mobile */
      xl: '3.6rem',
      /** h3 desktop, h2 mobile */
      lg: '2.6rem',
      /** h4 desktop, h3 mobile */
      md: '2.2rem',
      /** body main desktop */
      sm: '1.8rem',
      /** h5 desktop, h4 mobile */
      xs: '1.6rem',
      /** body main mobile */
      xxs: '1.4rem',
      /** captions, disclaimers - desktop & mobile */
      xxxs: '1.2rem',
    },

    lineHeights: {
      /** decorative super large text desktop */
      xxxl: '10rem',
      /** h1 desktop, decorative super large text mobile  */
      xxl: '6.2rem',
      /** h2 desktop, h1 mobile */
      xl: '4.8rem',
      /** h3 desktop, h2 mobile */
      lg: '3.8rem',
      /** h4 desktop, h3 mobile */
      md: '3rem',
      /** body main desktop */
      sm: '2.8rem',
      /** h5 desktop, h4 mobile */
      xs: '2.4rem',
      /** body main mobile */
      xxs: '2.2rem',
      /** captions, disclaimers - desktop & mobile */
      xxxs: '2.0rem',
    },

    fontFamily: {
      signifier: 'Signifier',
      nunitoSans: 'Nunito Sans',
      helvetica: 'Helvetica',
      sansSerif: 'sans-serif',
    },

    fontWeights: {
      /** Exclusively used with Nunito Sans - bold body text */
      bold: 900,
      /** Exclusively used with Signifier */
      semibold: 600,
      /** Exclusively used with Nunito Sans - normal body text */
      normal: 500,
    },
  },
  // TODO: https://github.com/sharesight/static-www/pull/574#discussion_r965332018
  spacing: {
    spacer01: '4px',
    spacer02: '8px',
    spacer03: '12px',
    spacer04: '16px',
    spacer05: '20px',
    spacer06: '24px',
    spacer07: '28px',
    spacer08: '32px',
    spacer09: '40px',
    spacer10: '48px',
    spacer11: '56px',
    spacer12: '60px',
    spacer13: '92px',
  },

  baseSize: 4,

  colors: {
    // Primary Colors
    blueberry40: '#202dac',
    carrot50: '#fc5c03',
    fig10: '#121023',

    // Secondary Colors
    lemon55: '#ffc21a',
    mushroom50: '#767689',

    // Neutrals
    black: '#000',
    cream: '#f8f8fa',
    tea: '#f6f5f4',
    white: '#fff',
  },
};

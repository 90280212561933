import { isAUNZ } from 'helpers/isAUNZ';
import type { CookieCategories, CookieInfo } from './types';

export const cookieInfoMap: CookieInfo[] = [
  {
    title: 'Essential cookies (always on)',
    description:
      'These cookies are always on to ensure the smoothest online experience (e.g. pricing discounts).',
    cookieType: 'essential',
  },
  {
    title: 'Functionality cookies',
    description:
      'For ease of use and convenience, these cookies remember the choices you made and personalise our website to make it most relevant for you.',
    cookieType: 'functionality',
  },
  {
    title: 'Performance cookies',
    description:
      'These cookies help us analyse and understand what areas of the website need to be improved.',
    cookieType: 'performance',
  },
  {
    title: 'Advertising cookies',
    description:
      'To make sure we show content most relevant to you, these cookies help us and our partners – including advertisers – understand what matters most to you.',
    cookieType: 'advertising',
  },
];

export const defaultPreferences: CookieCategories = {
  essential: true,
  advertising: isAUNZ,
  functionality: isAUNZ,
  performance: isAUNZ,
};

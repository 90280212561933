import { useCallback, useEffect, useState } from 'react';
import { useCookies as useReactCookies } from 'react-cookie';
import { CONSENT_COOKIE_NAME } from 'components/Cookie/ConsentCookie/constants';

export const useCookies = () => {
  const [cookies, setCookie, removeCookie] = useReactCookies();
  const [removeCookies, setRemoveCookies] = useState(false);

  const removeAllCookies = useCallback(() => {
    setRemoveCookies(true);
  }, [setRemoveCookies]);

  const removeConsentCookie = useCallback(() => {
    removeCookie(CONSENT_COOKIE_NAME, { path: '/', domain: '.sharesight.com' });
  }, [removeCookie]);

  useEffect(() => {
    const cookiesToRemove = Object.keys(cookies).filter(cookie => cookie !== CONSENT_COOKIE_NAME);

    if (removeCookies && !!cookiesToRemove.length) {
      cookiesToRemove.forEach(cookie => {
        removeCookie(cookie, { path: '/', domain: '.sharesight.com' });
      });
      setRemoveCookies(false);
    }

    if (!cookiesToRemove) setRemoveCookies(false);
  }, [cookies, removeCookie, removeCookies]);

  return { cookies, setCookie, removeCookie, removeAllCookies, removeConsentCookie };
};

import type { Reducer } from 'react';
import type { Action, CookieCategories } from './types';

export const cookiePreferencesReducer: Reducer<CookieCategories, Action> = (state, action) => {
  switch (action.type) {
    case 'accept-all':
      return {
        ...state,
        advertising: true,
        functionality: true,
        performance: true,
      };

    case 'reject-all':
      return {
        ...state,
        advertising: false,
        functionality: false,
        performance: false,
      };

    case 'individual-cookie':
      return { ...state, [action.preference.cookieType]: action.preference.value };

    default:
      // @ts-expect-error -- It's technically not possible to get here.
      throw new Error(`Unknown action.type passed to a reducer: ${action.type}.`);
  }
};

import TagManager from 'react-gtm-module';

export const denyPerformanceAdvertisingCookies = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'performance_advertising_cookies_denied',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

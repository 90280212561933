import TagManager from 'react-gtm-module';

export const allowAdvertisingCookies = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'advertising_cookies_allowed',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};
